<template>
  <div class="app-container">
    <main class="main-content">
        <transition
            :enter-active-class="swipeDirection === 'right' ? 'animate__animated animate__slideInRight' : 'animate__animated animate__slideOutRight'"
            :leave-active-class="swipeDirection === 'right' ? 'animate__animated animate__slideOutLeft' : 'animate__animated animate__slideInLeft'"
            mode="out-in"
        >
            <div class="pray" v-if="showText">
              <div class="image-text-container">
                <img v-if="step === 1" src="https://images.squarespace-cdn.com/content/v1/5a3bca1329f1877afb408d7e/1591292736601-IUHC2LRGU7QODR6O7OLF/cross.gif">
                <span v-if="step === 1">signe de croix</span>
              </div>
              <Credo v-if="step === 2" />
              <NotrePere v-if="step === 3" />

              <p v-if="step === 4">
                Pour plus de foi,
                <JVSM />
              </p>
              <p v-if="step === 5">
                Pour plus de Charité,
                <JVSM />
              </p>
              <p v-if="step === 6">
                Pour plus d’espérance,
                <JVSM />
              </p>
              <GloireAuPere v-if="step === 7" />
              <Fatima v-if="step === 8" />
            </div>
        </transition>
    </main>
    <footer>
        <div v-if="step === 1 || step === 2">
            <img id="swipe-tuto" src="https://cdn2.iconfinder.com/data/icons/hand-gestures-31/256/Artboard_6-512.png">
             <p>swipe</p>   
        </div>

        <div class="progress-indicators">
          <div
            v-for="n in 8"
            :key="n"
            class="tick"
            :class="{ active: n === step }"
            @click="goToStep(n)"
          ></div>
        </div>
        <!-- <button class="next-button" @click="next()">Suivant</button> -->
    </footer>
  </div>
</template>

<script>
import 'animate.css/animate.min.css';
import Credo from "@/components/Credo";
import NotrePere from "@/components/NotrePere";
import JVSM from "@/components/JVSM";
import GloireAuPere from "@/components/GloireAuPere";
import Fatima from "@/components/Fatima";

export default {
    name: "Start",

    components: {
        Credo,
        NotrePere,
        JVSM,
        GloireAuPere,
        Fatima
    },

    data() {
        return {
            block: 0,
            step: 1,
            progress: 0,
            touchstartX: 0,
            touchendX: 0,
            lastSwipeTime: 0,
            showText: true,
            swipeDirection: 'right'
        };
    },
    mounted() {
        document.addEventListener('touchstart', e => {
            this.touchstartX = e.changedTouches[0].screenX;
        }, false);

        document.addEventListener('touchend', e => {
            this.touchendX = e.changedTouches[0].screenX;
            this.checkSwipeDirection();
        }, false);
    },

    methods: {
        fadeText() {
            this.showText = false;
            setTimeout(() => {
              this.showText = true;
            }, 300);
        },

        checkSwipeDirection() {
            const currentTime = new Date().getTime();
            // Swipe vers la gauche (décrémenter)
            if (this.touchstartX < this.touchendX && currentTime - this.lastSwipeTime > 500) {
              this.lastSwipeTime = currentTime;
              this.prev();
            }
            // Swipe vers la droite (incrémenter)
            else if (this.touchstartX > this.touchendX && currentTime - this.lastSwipeTime > 500) {
              this.lastSwipeTime = currentTime;
              this.next();
            }
        },

        goToStep(stepNumber) {
            this.step = stepNumber;
            this.updateProgress();
            this.fadeText();
        },

        prev() {
            this.swipeDirection = 'left';

            if (this.step > 1) {
              this.step--;
            }
        },

        next() {
            this.swipeDirection = 'right';
            if (this.step === 8) {
              return this.$router.push({ name: "pray" });
            }
            else {
                this.step++;
            }
            this.fadeText();
        }
    }
};
</script>

<style type="text/css">

@keyframes rotate-image {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(30deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

#swipe-tuto {
    width: 60px;
    display: block;
    margin: 0 auto;
    animation: rotate-image 2s infinite linear;
}

.progress-indicators {
  padding-top: 20px;
  display: flex;
  justify-content: center;
}

.tick {
  width: 10px; /* Taille des indicateurs */
  height: 10px;
  border-radius: 50%; /* Les rend circulaires */
  background-color: #ccc; /* Couleur par défaut */
  margin: 0 5px; /* Espacement entre les indicateurs */
}

.tick.active {
  background-color: #000; /* Couleur de l'indicateur actif */
}

html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: Inter;
  overflow: hidden;
}

/* Stylisez le conteneur de l'application pour qu'il occupe toute la hauteur et soit flex */
.app-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh; /* Utilisez min-height pour occuper au moins toute la hauteur de la fenêtre */
  position: relative;
}

.progress-container {
    margin-top: 10px;
    margin-bottom: 5px;
  width: 100%;
  background-color: #000;
  border-radius: 10px;
}

.progress-bar {
  height: 10px;
  background-color: #FFF;
  width: 0;
  border-radius: 10px;
  transition: width 0.5s ease;
}

.main-content {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 160px); /* Hauteur de la vue moins la hauteur de l'en-tête et du pied de page */
  margin: 0 20px;
  overflow: auto; /* Ajoutez un défilement si le contenu dépasse la hauteur de la fenêtre */
  -webkit-overflow-scrolling: touch;
}

.pray {
  flex: 1; /* Cela permet à .pray de remplir tout l'espace disponible */
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  text-align: center;
}

footer {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 10px 20px;
  text-align: center;
  z-index: 1000; /* S'assure que le pied de page reste au-dessus du contenu défilant */
}

/* Stylisez le bouton pour qu'il soit large et centré */
.next-button {
  width: calc(100% - 40px); /* Ajustez cette valeur pour augmenter l'espace sur les côtés */
  margin: auto; /* Ceci centre le bouton dans le footer */
  padding: 20px;
  font-size: 16px;
  color: white;
  background-color: #000; /* Bouton noir */
  border: none;
  cursor: pointer;
  border-radius: 17px;
}

/* Ajouter des styles pour les états hover et focus du bouton */
.next-button:hover,
.next-button:focus {
  background-color: #444; /* Assombrir le bouton au survol */
}

/* Style pour garantir que le contenu est bien centré et responsive */
@media (max-width: 600px) {
  .next-button {
    width: calc(100% - 20px); /* Espaces latéraux plus petits pour les appareils mobiles */
  }
}

/* Style pour garantir que le contenu est bien centré et responsive */
@media (max-width: 600px) {
  .content-header, .main-content {
    padding: 10px;
  }

  footer {
    padding: 10px 0; /* Padding plus petit pour les appareils mobiles */
    margin: 15px;
  }

  .next-button {
    width: 100%; /* Le bouton prend toute la largeur */
  }
}

.animate__animated {
  /* Réduisez la durée à, par exemple, 0.3 secondes */
  animation-duration: 0.3s !important;
}

.image-text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>